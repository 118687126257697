const portfolioItems = [
    {
        title: 'Front Desk',
        sinopsis: 'Voice First Visitor Management',
        desc: 'From people to packages to lunch. Handling anything that comes through your front door is as easy as a conversation. A voice first (Alexa) and Slack based visitor management tool that is built with node.js, and AWS serverless services. Deployed using Fullstack Serverless.',
        live: 'https://tryfrontdesk.co',
        img: 'https://tryfrontdesk.co/assets/img/og-img.png',
        idx: 1
    },
    {
        title: 'CO Wild Bees',
        sinopsis: 'Finding, Tracking, and Documenting feral honeybees in Colorado',
        desc: 'Website for a service I provide as a beekeeper. Built using Gatsby and deployed with Fullstack Serverless',
        img: 'https://cowildbees.com/CO_Wild_Bees_Flag_of_Colorado.png',
        live: 'https://cowildbees.com',
        idx: 2
    },
    {
        title: 'Hahnest Eats',
        sinopsis: 'A food blog by the Hahn children',
        desc: 'Dedicated to the cookbook our mom wrote for us when we moved out. This is a recipe blog built with Gatsby and using Netlify CMS for content management',
        img: 'https://ucarecdn.com/e857cb85-1662-4117-8701-ce3938b5593f/',
        live: 'https://hahnesteats.com',
        idx: 3
    },
    {
        title: 'Fullstack Serverless',
        sinopsis: 'Plugin for serverless.com to deploy fullstack apps on AWS',
        desc: 'A serverless plugin that automatically creates an AWS CloudFront distribution that serves static web content from S3 and optionally routes API traffic to API Gateway.',
        img: '/logos/serverless.svg',
        repo: 'https://github.com/MadSkills-io/fullstack-serverless',
        live: 'https://www.npmjs.com/package/fullstack-serverless',
        idx: 4
    },
    {
        title: 'Seeds',
        sinopsis: 'Some of my seeds are for sale at the Experiential Farm Network',
        desc: 'Grains, perennial vegetables, and other assorted seeds.',
        img: 'https://www.experimentalfarmnetwork.org/themes/minimus/images/efn_logo_long_bold.png',
        live: 'https://store.experimentalfarmnetwork.org/',
        idx: 5
    }

]

export default portfolioItems